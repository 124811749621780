export default [
  {
    path: '/orderManage',
    name: 'OrderManage',
    component: () =>
      import(
        /* webpackChunkName: "shopManage" */ '@/views/orderManage/OrderManage'
      )
  }
]
