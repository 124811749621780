export default [
  {
    path: '/financeManage',
    name: 'FinanceManage',
    component: () =>
      import(
        /* webpackChunkName: "shopManage" */ '@/views/financeManage/FinanceManage'
      )
  },
  {
    path: '/FinanceDataAnalysis',
    name: 'FinanceDataAnalysis',
    component: () =>
      import(
        /* webpackChunkName: "shopManage" */ '@/views/financeManage/FinanceDataAnalysis'
      )
  },
  {
    path: '/FinanceDataAdd',
    name: 'FinanceDataAdd',
    component: () =>
      import(
        /* webpackChunkName: "shopManage" */ '@/views/financeManage/FinanceDataAdd'
      )
  }
]
