import router from '@/router'
import store from './store'

// 白名单
const whiteList = ['/login', '/register']

/**
 * 路由前置守卫
 */
router.beforeEach(async (to, from, next) => {
  // 存在 token ，进入主页
  if (store.getters.token) {
    if (to.path === '/login') {
      next('/')
    } else {
      // 获取用户头像并缓存
      if (
        !store.getters.userHeader ||
        Object.keys(store.getters.userHeader).length === 0
      ) {
        await store.dispatch('user/getUserHeader')
      }
      // 获取环境信息
      if (!store.getters.ENV || Object.keys(store.getters.ENV).length === 0) {
        await store.dispatch('user/getEnvInfo')
      }
      // 判断用户资料是否获取
      if (!store.getters.hasUserInfo) {
        const { permissions } = await store.dispatch('user/getUserInfo')
        const filterRoutes = await store.dispatch(
          'permission/filterRoutes',
          permissions
        )
        // 利用 addRoute 循环添加
        filterRoutes.forEach((item) => {
          router.addRoute(item)
        })
        // 添加完动态路由之后，需要在进行一次主动跳转
        return next(to.path)
      }
      // TODO: Bug
      if (!store.state.permission.routes) {
        const { permissions } = store.state.user.userInfo
        const filterRoutes = await store.dispatch(
          'permission/filterRoutes',
          permissions
        )
        // 利用 addRoute 循环添加
        filterRoutes.forEach((item) => {
          router.addRoute(item)
        })
        // 添加完动态路由之后，需要在进行一次主动跳转
        return next(to.path)
      }
      next()
    }
  } else {
    // 没有token的情况下，可以进入白名单
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      next('/login')
    }
  }
})
