// token
export const TOKEN = 'token'
// userInfo
export const USERINFO = 'userInfo'
// userPhoto
export const USERHEADER = 'userHeader'
// routes
export const ROUTES = 'routes'
// token 时间戳
export const TIME_STAMP = 'timeStamp'
// 超时时长(毫秒) 4小时
export const TOKEN_TIMEOUT_VALUE = 4 * 3600 * 1000
// shopname
export const SHOPNAME = '小卖铺'
// payMethod
export const PAYMETHOD = 'payMethod'
// PageSize
export const PAGESIZE = 15
// ENV
export const ENV = 'ENV'
