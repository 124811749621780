export default [
  {
    path: '/BatchUpload',
    name: 'BatchUpload',
    component: () =>
      import(
        /* webpackChunkName: "shopManage" */ '@/views/batchUpload/BatchUpload'
      )
  }
]
