import {
  reqUserLogin,
  reqUserRegister,
  reqUserInfo,
  reqUserHeader,
  reqEnvInfo
} from '@/api'
import md5 from 'md5'
import { setItem, getItem, removeAllItem } from '@/utils/storage'
import { TOKEN, USERINFO, USERHEADER, ENV } from '@/constant'
import router, { resetRouter } from '@/router'
import { setTimeStamp } from '@/utils/auth'

const state = () => ({
  token: getItem(TOKEN) || '',
  userInfo: getItem(USERINFO) || {},
  userHeader: getItem(USERHEADER) || {}
})

const mutations = {
  setToken(state, token) {
    state.token = token
    setItem(TOKEN, token)
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
    setItem(USERINFO, userInfo)
  },
  setUserHeader(state, userHeader) {
    state.userHeader = userHeader
    setItem(USERHEADER, userHeader)
  },
  setEnvInfo(state, env) {
    state.ENV = env
    setItem(ENV, env)
  }
}

const actions = {
  // 登录业务
  userLogin(context, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      reqUserLogin({
        username,
        password: md5(password)
      })
        .then((data) => {
          this.commit('user/setToken', data.token)
          // 保存登录时间
          setTimeStamp()
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async getUserInfo(context) {
    const res = await reqUserInfo()
    this.commit('user/setUserInfo', res)
    return res
  },
  async getUserHeader(context) {
    const res = await reqUserHeader()
    if (res.list.length > 0) {
      const path = res.list[0].value
      this.commit('user/setUserHeader', path)
    }
  },
  async getEnvInfo(context) {
    const res = await reqEnvInfo()
    if (res.list.length > 0) {
      const env = res.list[0].value
      this.commit('user/setEnvInfo', env)
    }
  },
  // 用户注册
  async userRegister(context, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      reqUserRegister({
        username,
        password: md5(password)
      })
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  // 退出登录
  userLogout() {
    resetRouter()
    this.commit('user/setToken', '')
    this.commit('user/setUserInfo', {})
    this.commit('user/setUserHeader', '')
    this.commit('user/setEnvInfo', '')
    removeAllItem()
    router.push('/login')
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
