import {
  createRouter,
  createWebHashHistory,
  createWebHistory
} from 'vue-router'
import Shopping from './modules/Shopping'
import OrderManage from './modules/OrderManage'
import GoodsManage from './modules/GoodsManage'
import AccountManage from './modules/AccountManage'
import FinanceManage from './modules/FinanceManage'
import BatchUpload from './modules/BatchUpload'
import store from '@/store'

/**
 * 公开路由表
 */
export const publicRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/Login')
  },
  {
    path: '/register',
    name: 'Register',
    component: () =>
      import(/* webpackChunkName: "register" */ '@/views/register/Register')
  }
]

/**
 * 私有路由表
 */
export const privateRoutes = {
  Shopping,
  OrderManage,
  GoodsManage,
  AccountManage,
  FinanceManage,
  BatchUpload
}

/**
 * 初始化路由表
 */
export function resetRouter() {
  if (store.getters.userInfo && store.getters.userInfo.permissions) {
    const permissions = store.getters.userInfo.permissions
    permissions.forEach((permission) => {
      router.removeRoute(permission.name)
    })
  }
}

const router = createRouter({
  history:
    process.env.NODE_ENV === 'production'
      ? createWebHistory()
      : createWebHashHistory(),
  routes: publicRoutes
})

export default router
