import { createStore } from 'vuex'
import user from './modules/user'
import cart from './modules/cart'
import permission from './modules/permission'
import getters from './getters'

export default createStore({
  getters,
  modules: {
    user,
    cart,
    permission
  },
  state: {},
  mutations: {}
})
