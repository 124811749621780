// 专门处理权限路由的模块
import { publicRoutes, privateRoutes } from '@/router'
export default {
  namespaced: true,
  state: {
    routes: publicRoutes
  },
  mutations: {
    setRoutes(state, newRoutes) {
      state.routes = [...publicRoutes, ...newRoutes]
    }
  },
  actions: {
    filterRoutes(context, menus) {
      const routes = []
      // 路由权限匹配
      menus.forEach((item) => {
        routes.push(...privateRoutes[item.name])
      })

      context.commit('setRoutes', routes)
      return routes
    }
  }
}
