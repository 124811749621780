import requests from '@/utils/request'

// 获取环境信息
export const reqEnvInfo = (data) =>
  requests({ url: '/common/paramList?key=Env', method: 'GET' })

// 登录
export const reqUserLogin = (data) =>
  requests({
    url: `/login?username=${data.username}&password=${data.password}`,
    method: 'POST'
  })

// 获取用户头像
export const reqUserHeader = () =>
  requests({ url: '/common/paramList?key=UserHeader', method: 'GET' })

// export const reqUserLogin = (data) =>
//   requests({ url: '/login', data, method: 'POST' })

// 注册
export const reqUserRegister = (data) =>
  requests({ url: '/account/register', data, method: 'POST' })

// 获取用户信息
export const reqUserInfo = () =>
  requests({ url: '/account/userInfo', method: 'GET' })

// 修改密码
export const reqUpdatePassword = (data) =>
  requests({ url: '/account/updatePassword', data, method: 'POST' })

// 修改用户信息
export const reqUserInfoUpdate = (data) =>
  requests({ url: '/account/update', data, method: 'POST' })

// 重置用户密码
export const reqResetPassword = (data) => {
  requests({ url: '/admin/account/resetPassword', data, method: 'POST' })
}

// 获取商品列表
export const reqGetGoodsList = (data) =>
  requests({ url: '/goods/search', data, method: 'POST' })

// 获取商品类型
export const reqGetGoodsType = () =>
  requests({ url: '/common/paramList?key=GoodsType', method: 'GET' })

// 获取购物车By token
export const getShopCart = (data) =>
  requests({ url: '/shopcart/load', params: data, method: 'GET' })

// 清空购物车
export const clearCart = (data) =>
  requests({ url: '/shopcart/clear', data, method: 'POST' })

// 修改购物车By Product
export const changeCartSkuInfo = (data) =>
  requests({ url: '/shopcart/updateSku', data, method: 'POST' })

// 创建订单
export const createOrder = (data) =>
  requests({ url: '/order/create', data, method: 'POST' })

// 获取订单
export const getOrder = (data) =>
  requests({ url: '/order/search', data, method: 'POST' })

// 获取订单信息ById
export const getOrderInfo = (orderId) =>
  requests({ url: `/order/orderInfo/?orderNum=${orderId}`, method: 'GET' })

// 取消订单
export const cancleOrder = (data) =>
  requests({ url: '/order/cancel', data, method: 'POST' })

// 确认支付
export const confirmPay = (data) =>
  requests({ url: '/order/confirmPay', data, method: 'POST' })

// 获取商品信息
export const getGoodsInfo = (data) =>
  requests({ url: '/goods/goodsInfo', params: data, method: 'GET' })

// 获取商品状态
export const getGoodsStatus = (data) =>
  requests({ url: '/common/paramList', params: data, method: 'GET' })

// 获取商品排序方式
export const getGoodsOrderMethods = (data) =>
  requests({ url: '/common/paramList', params: data, method: 'GET' })

// 获取订单状态信息
export const getPayStatus = (data) =>
  requests({ url: '/common/paramList', params: data, method: 'GET' })

// 获取订单排序信息
export const getOrderMethods = (data) =>
  requests({ url: '/common/paramList', params: data, method: 'GET' })

// 获取倒计时时间
export const reqGetCountDownTime = (data) =>
  requests({ url: '/common/paramList', params: data, method: 'GET' })

// 修改商品信息
export const reqChangeGoodsInfo = (data) =>
  requests({ url: '/goods/admin/update', data, method: 'POST' })

// 新增商品信息
export const reqAddGoodsInfo = (data) =>
  requests({ url: '/goods/admin/create', data, method: 'POST' })

// 用户管理查询用户列表
export const reqSearchUserList = (data) =>
  requests({ url: '/admin/account/search', data, method: 'POST' })

// 用户管理获取用户信息
export const reqAdminUserInfo = (data) =>
  requests({ url: '/admin/account/userInfo', params: data, method: 'GET' })

// 用户管理修改用户信息
export const reqAdminChangeUserInfo = (data) =>
  requests({ url: '/admin/account/update', data, method: 'POST' })

// 用户管理删除用户
export const reqAdminDeleteUser = (data) =>
  requests({ url: '/admin/account/remove', data, method: 'POST' })

// 用户管理更新用户状态
export const reqAdminChangeUserStatus = (data) =>
  requests({ url: '/admin/account/updateStatus', data, method: 'POST' })

// 用户管理获取角色列表
export const reqAdminGetRoles = (data) =>
  requests({ url: '/admin/account/getRoles', params: data, method: 'GET' })

// 用户管理查询订单列表
export const reqSearchOrderList = (data) =>
  requests({ url: '/admin/order/search', data, method: 'POST' })

// 用户管理查询订单列表
export const reqAdminCancleOrder = (data) =>
  requests({ url: '/admin/order/cancel', data, method: 'POST' })

// 管理员获取订单信息ById
export const getAdminOrderInfo = (orderNum) =>
  requests({
    url: `/admin/order/orderInfo/${orderNum}`,
    method: 'GET'
  })

// 用户管理获取用户状态
export const reqAdminGetUserStatus = (data) =>
  requests({ url: '/common/paramList', params: data, method: 'GET' })

// 商品管理更新商品状态
export const reqUpdateGoodsStatus = (data) =>
  requests({ url: '/goods/admin/updateStatus', data, method: 'POST' })

// 查询区间总计
export const reqPeriodAmount = (data) =>
  requests({
    url: '/admin/order/operationManagementTotal',
    params: data,
    method: 'GET'
  })

// 查询每日总计
export const reqDailyAmount = (data) =>
  requests({
    url: '/admin/order/operationManagement',
    data,
    method: 'POST'
  })

// 获取财务数据类型

export const getFinanceDataType = (data) =>
  requests({ url: '/common/paramList', params: data, method: 'GET' })

// 财务管理-获取财务记录列表
export const reqGetFinanceRecord = (data) =>
  requests({
    url: '/admin/order/finance/financeRecord',
    data,
    method: 'POST'
  })

// 财务管理-财务报告展示
export const reqGetfinancialReport = (data) =>
  requests({
    url: '/admin/order/finance/financeReport',
    params: data,
    method: 'GET'
  })

export const reqGetFinanceType = () =>
  requests({ url: '/common/paramList?key=financeType', method: 'GET' })

// 新增财务数据
export const reqAddFinanceinfo = (data) =>
  requests({ url: '/admin/order/finance/create', data, method: 'POST' })

// 财务数据报表下载
export const reqFinanceRecordDownload = (data) =>
  requests({
    url: '/admin/order/finance/financeRecordDownload',
    data,
    method: 'POST'
  })
