import axios from 'axios'
import store from '@/store'
import { isCheckTimeout } from '@/utils/auth'

export const baseURL = process.env.VUE_APP_BASE_API

const requests = axios.create({
  baseURL,
  // baseURL:
  //   'https://www.fastmock.site/mock/1d82b6ecaf574d47dcbaf4d0d8efd1ea/api',
  // baseURL: 'http://www.shibahao.cn:2002/api/',
  timeout: 10000,
  withCredentials: false
})

// 请求拦截器
requests.interceptors.request.use((config) => {
  // 携带token带给服务器
  if (store.getters.token) {
    if (isCheckTimeout()) {
      // 登出操作
      store.dispatch('user/userLogout')
      return Promise.reject(new Error('token 失效'))
    }
    config.headers.Authorization = store.getters.token
  }

  return config
})

// 响应拦截器
requests.interceptors.response.use(
  (response) => {
    const { code, message, data } = response.data
    //   要根据success的成功与否决定下面的操作
    if (code === 200) {
      return data
    } else if (code === 1004) {
      // token无效
      store.dispatch('user/userLogout')
    } else {
      // 业务错误
      return Promise.reject(new Error(code + ':' + message))
    }
  },
  (error) => {
    // 处理 token 超时问题
    if (
      error.response &&
      error.response.data &&
      error.response.data.code === 401
    ) {
      // token超时
      store.dispatch('user/userLogout')
    }
    return Promise.reject(error)
  }
)

export const get = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    requests.get(url, { params }).then(
      (data) => {
        resolve(data)
      },
      (err) => {
        reject(err)
      }
    )
  })
}

export const post = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    requests
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(
        (response) => {
          resolve(response.data)
        },
        (err) => {
          reject(err)
        }
      )
  })
}

export default requests
