export default [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/Home')
  },
  {
    path: '/shop/',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "shop" */ '@/views/shop/Shop')
  },
  {
    path: '/shop/goodsDetail/:id',
    name: 'GoodsDetail',
    component: () =>
      import(/* webpackChunkName: "order" */ '@/views/shop/goodsDetail')
  },
  {
    path: '/orderConfirmation/',
    name: 'OrderConfirmation',
    component: () =>
      import(
        /* webpackChunkName: "order" */ '@/views/orderConfirmation/OrderConfirmation'
      )
  },
  {
    path: '/orderConfirmation',
    name: 'Order',
    component: () =>
      import(
        /* webpackChunkName: "orderConfirmation" */ '@/views/orderConfirmation/Order'
      )
  },
  {
    path: '/OrderList',
    name: 'OrderList',
    component: () =>
      import(/* webpackChunkName: "order" */ '@/views/order/OrderList')
  },
  {
    path: '/OrderInfo/:id',
    name: 'OrderInfo',
    component: () =>
      import(/* webpackChunkName: "order" */ '@/views/orderInfo/OrderInfo')
  },
  {
    path: '/AdminOrderInfo/:id',
    name: 'AdminOrderInfo',
    component: () =>
      import(
        /* webpackChunkName: "order" */ '@/views/orderManage/AdminOrderInfo'
      )
  },
  {
    path: '/BatchUpload',
    name: 'BatchUpload',
    component: () =>
      import(/* webpackChunkName: "order" */ '@/views/batchUpload/BatchUpload')
  },
  {
    path: '/Pay/:id',
    name: 'Pay',
    component: () => import(/* webpackChunkName: "order" */ '@/views/pay/Pay')
  },
  {
    path: '/My',
    name: 'My',
    component: () => import(/* webpackChunkName: "order" */ '@/views/my/My')
  },
  {
    path: '/My/updateProfile',
    name: 'UserInfoUpdate',
    component: () =>
      import(/* webpackChunkName: "order" */ '@/views/my/UserInfoUpdate')
  }
]
