export default [
  {
    path: '/accountManage',
    name: 'AccountManage',
    component: () =>
      import(
        /* webpackChunkName: "shopManage" */ '@/views/accountManage/AccountManage'
      )
  },
  {
    path: '/editAccountInfo/:id',
    name: 'EditAccountInfo',
    component: () =>
      import(
        /* webpackChunkName: "shopManage" */ '@/views/accountManage/EditAccountInfo'
      )
  }
]
