import { getShopCart, changeCartSkuInfo, clearCart } from '@/api'

const setLocalCartList = (state) => {
  const { cartList } = state
  const cartListString = JSON.stringify(cartList)
  localStorage.cartList = cartListString
}

const getLocaCartList = () => {
  try {
    return JSON.parse(localStorage.cartList)
  } catch (e) {
    return {}
  }
}

const state = () => ({
  cartList: getLocaCartList()
})

const mutations = {
  changeCartItemInfo(state, payload) {
    const { productId, productInfo } = payload
    const productList = state.cartList?.productList || {}

    let product = productList?.[productId]
    if (!product) {
      productInfo.count = 0
      product = productInfo
    }
    product.count = product.count + payload.num
    if (payload.num > 0) {
      product.check = true
    }
    if (product.count < 0) {
      product.count = 0
    }
    productList[productId] = product
    state.cartList.productList = productList
    setLocalCartList(state)
  },
  changeCartItemChecked(state, payload) {
    const { productId } = payload
    const product = state.cartList.productList[productId]
    product.check = !product.check
    setLocalCartList(state)
  },
  cleanCartProducts(state) {
    state.cartList.productList = {}
    setLocalCartList(state)
  },
  setCartItemsChecked(state, checked) {
    const products = state.cartList.productList
    if (products) {
      for (const key in products) {
        const product = products[key]
        product.check = checked
      }
    }
    setLocalCartList(state)
  },
  clearCartData(state) {
    state.cartList.productList = {}
  },
  setCartData(state, productList) {
    state.cartList.productList = productList
    setLocalCartList(state)
  }
}

const actions = {
  loadCartFromServer(context, data) {
    return new Promise((resolve, reject) => {
      getShopCart(data)
        .then((data) => {
          this.commit('cart/setCartData', data.productList)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  changeCartSkuInfo(context, payload) {
    const { product, user } = payload
    return new Promise((resolve, reject) => {
      changeCartSkuInfo({
        userId: user.id,
        goodsId: product.id,
        quantity: product.count
      })
        .then((data) => {
          this.commit('cart/setCartData', data.productList)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  cleanCartProducts(context, data) {
    return new Promise((resolve, reject) => {
      clearCart(data)
        .then((data) => {
          this.commit('cart/setCartData', [])
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
