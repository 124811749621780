import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import route from './router'
import store from './store'
// 路由鉴权
import './permission.js'
import 'normalize.css'
import './style/index.scss'

// 按需引入nutui
import '@nutui/nutui/dist/style.css'
import {
  Menu,
  MenuItem,
  Swipe,
  Button,
  Navbar,
  Icon,
  Input,
  Uploader,
  Progress,
  InputNumber,
  Swiper,
  SwiperItem,
  TextArea,
  Radio,
  RadioGroup,
  Dialog,
  Popup,
  OverLay,
  Picker,
  Cell,
  Switch,
  Checkbox,
  CheckboxGroup,
  InfiniteLoading,
  CountDown,
  Popover,
  DatePicker,
  Divider,
  Calendar,
  Collapse,
  CollapseItem,
  Table,
  FixedNav,
  Drag
} from '@nutui/nutui'

const app = createApp(App)
app
  .use(store)
  .use(router)
  .use(route)
  .use(Menu)
  .use(MenuItem)
  .use(Swipe)
  .use(Button)
  .use(Navbar)
  .use(Icon)
  .use(Input)
  .use(Uploader)
  .use(Progress)
  .use(InputNumber)
  .use(Swiper)
  .use(SwiperItem)
  .use(TextArea)
  .use(Radio)
  .use(RadioGroup)
  .use(Dialog)
  .use(Popup)
  .use(OverLay)
  .use(Picker)
  .use(Cell)
  .use(Switch)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(InfiniteLoading)
  .use(CountDown)
  .use(Popover)
  .use(DatePicker)
  .use(Divider)
  .use(Calendar)
  .use(Collapse)
  .use(CollapseItem)
  .use(Table)
  .use(FixedNav)
  .use(Drag)
  .mount('#app')
