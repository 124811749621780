export default [
  {
    path: '/goodsManage',
    name: 'GoodsManage',
    component: () =>
      import(
        /* webpackChunkName: "shopManage" */ '@/views/goodsManage/GoodsManage'
      )
  },
  {
    path: '/editGoodsInfo/:id',
    name: 'EditGoodsInfo',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/goodsManage/EditGoodsInfo')
  }
]
