const getters = {
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo,
  userHeader: (state) => state.user.userHeader,
  ENV: (state) => state.user.ENV,
  /**
   * @returns true 表示已存在用户信息
   */
  hasUserInfo: (state) => {
    return JSON.stringify(state.user.userInfo) !== '{}'
  },
  hasUserHeader: (state) => {
    return JSON.stringify(state.user.UserHeader) !== '{}'
  },
  hasENV: (state) => {
    return JSON.stringify(state.user.ENV) !== '{}'
  }
}
export default getters
